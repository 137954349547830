import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.saleKeys,
      getDataWithKey: _vm.getDataWithKey,
      onSelectChange: _vm.handleProvinceChange
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.addColumns,
      rowKey: function rowKey(record, index) {
        return index;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn(text, record) {
        return [record.image ? _c("img", {
          staticClass: "product_img",
          attrs: {
            src: record.image
          },
          on: {
            click: function click($event) {
              return _vm.preview(text);
            }
          }
        }) : _c("span", [_vm._v("---")])];
      }
    }, {
      key: "brandidmsg",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.brandidmsg || "---"))])];
      }
    }, {
      key: "sku",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.sku || "---"))])];
      }
    }, {
      key: "name",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.name || "---"))])];
      }
    }, {
      key: "price",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.price || "---"))])];
      }
    }, {
      key: "totalCount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.price * record.count))])];
      }
    }, {
      key: "totalBond",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.bond * record.count))])];
      }
    }, {
      key: "managementcategory",
      fn: function fn(text, record) {
        return [record.parentcategoryidmsg ? _c("span", [_vm._v(" " + _vm._s(record.parentcategoryidmsg + "-" + record.categoryidmsg) + " ")]) : _c("span", [_vm._v("---")])];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };